// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-sutra-js": () => import("/mnt/c/Users/admin/sutrasyogaspa/src/templates/sutra.js" /* webpackChunkName: "component---src-templates-sutra-js" */),
  "component---src-pages-404-js": () => import("/mnt/c/Users/admin/sutrasyogaspa/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/mnt/c/Users/admin/sutrasyogaspa/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("/mnt/c/Users/admin/sutrasyogaspa/.cache/data.json")

